export const MODAL_LOGIN = 'modal-login';
export const MODAL_HAMBURGER = 'modal-hamburger';
export const MODAL_PROFILE = 'modal-profile';
export const MODAL_REGISTER = 'modal-register';
export const MODAL_ACCESS_REQUESTED = 'modal-access-requested';
export const MODAL_SEARCH = 'modal-search';
export const MODAL_QR = 'modal-qr';
export const MODAL_CITE = 'modal-cite';
export const MODAL_CONTENT_REQUEST = 'modal-content-request';

const { push } = useTagmanager();

export const useModalStore = defineStore('modal-store', () => {
    const activeModal = ref(null);
    const previousModal = ref(null);

    const toggleModal = (modal) => {
        previousModal.value = activeModal.value;
        if (activeModal.value === modal) {
            closeModal();
        } else {
            activeModal.value = modal;
        }

        if (modal === MODAL_ACCESS_REQUESTED) {
            push({
                event: 'create_account_step_access_requested'
            });
        }

        if (modal === MODAL_REGISTER) {
            push({
                event: 'create_account_step_details'
            });
        }
    };

    const closeModal = () => {
        previousModal.value = activeModal.value;
        activeModal.value = null;
    };

    return {
        activeModal,
        previousModal,
        toggleModal,
        closeModal
    };
});